var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        width: _vm.modalWidth,
        visible: _vm.visible,
        title: "部门搜索",
        confirmLoading: _vm.confirmLoading,
        cancelText: "关闭",
        wrapClassName: "ant-modal-cust-warp",
      },
      on: { ok: _vm.handleSubmit, cancel: _vm.handleCancel },
    },
    [
      [
        _c(
          "a-form",
          { attrs: { form: _vm.form } },
          [
            _c(
              "a-form-item",
              {
                attrs: {
                  labelCol: _vm.labelCol,
                  wrapperCol: _vm.wrapperCol,
                  label: "上级部门",
                },
              },
              [
                _c("a-tree", {
                  attrs: {
                    multiple: "",
                    treeCheckable: "tree",
                    checkable: "",
                    expandedKeys: _vm.expandedKeysss,
                    checkedKeys: _vm.checkedKeys,
                    allowClear: "true",
                    checkStrictly: true,
                    dropdownStyle: { maxHeight: "200px", overflow: "auto" },
                    treeData: _vm.departTree,
                    placeholder: "请选择上级部门",
                  },
                  on: { expand: _vm.onExpand, check: _vm.onCheck },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }