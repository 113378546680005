var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-drawer",
    {
      staticStyle: {
        height: "100%",
        overflow: "auto",
        "padding-bottom": "53px",
      },
      attrs: {
        title: _vm.title,
        maskClosable: true,
        width: _vm.drawerWidth,
        placement: "right",
        closable: true,
        visible: _vm.visible,
      },
      on: { close: _vm.handleCancel },
    },
    [
      _c("template", { slot: "title" }, [
        _c("div", { staticStyle: { width: "100%" } }, [
          _c("span", [_vm._v(_vm._s(_vm.title))]),
          _c(
            "span",
            {
              staticStyle: {
                display: "inline-block",
                width: "calc(100% - 51px)",
                "padding-right": "10px",
                "text-align": "right",
              },
            },
            [
              _c("a-button", {
                staticStyle: { height: "20px", width: "20px", border: "0px" },
                attrs: { icon: "appstore" },
                on: { click: _vm.toggleScreen },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.confirmLoading } },
        [
          _c(
            "a-form",
            { attrs: { form: _vm.form } },
            [
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "用户账号",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: ["username", _vm.validatorRules.username],
                        expression: "[ 'username', validatorRules.username]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: {
                      placeholder: "请输入用户账号",
                      readOnly: !!_vm.model.id,
                    },
                  }),
                ],
                1
              ),
              !_vm.model.id
                ? [
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: "登录密码",
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                        },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: ["password", _vm.validatorRules.password],
                              expression:
                                "[ 'password',validatorRules.password]",
                            },
                          ],
                          attrs: {
                            type: "password",
                            placeholder: "请输入登录密码",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "a-form-item",
                      {
                        attrs: {
                          label: "确认密码",
                          labelCol: _vm.labelCol,
                          wrapperCol: _vm.wrapperCol,
                        },
                      },
                      [
                        _c("a-input", {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "confirmpassword",
                                _vm.validatorRules.confirmpassword,
                              ],
                              expression:
                                "[ 'confirmpassword', validatorRules.confirmpassword]",
                            },
                          ],
                          attrs: {
                            type: "password",
                            placeholder: "请重新输入登录密码",
                          },
                          on: { blur: _vm.handleConfirmBlur },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "用户姓名",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: ["realname", _vm.validatorRules.realname],
                        expression: "[ 'realname', validatorRules.realname]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { placeholder: "请输入用户姓名" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "工号",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator.trim",
                        value: ["workNo", _vm.validatorRules.workNo],
                        expression: "[ 'workNo', validatorRules.workNo]",
                        modifiers: { trim: true },
                      },
                    ],
                    attrs: { placeholder: "请输入工号" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "职务",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("j-select-position", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["post", {}],
                        expression: "['post', {}]",
                      },
                    ],
                    attrs: { placeholder: "请选择职务", multiple: false },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.roleDisabled,
                      expression: "!roleDisabled",
                    },
                  ],
                  attrs: {
                    label: "角色分配",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        mode: "multiple",
                        disabled: _vm.departDisabled,
                        placeholder: "请选择用户角色",
                        optionFilterProp: "children",
                        getPopupContainer: (target) => target.parentNode,
                      },
                      model: {
                        value: _vm.selectedRole,
                        callback: function ($$v) {
                          _vm.selectedRole = $$v
                        },
                        expression: "selectedRole",
                      },
                    },
                    _vm._l(_vm.roleList, function (role, roleindex) {
                      return _c(
                        "a-select-option",
                        {
                          key: roleindex.toString(),
                          attrs: { value: role.id },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(role.roleName) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.departDisabled,
                      expression: "!departDisabled",
                    },
                  ],
                  attrs: {
                    label: "部门分配",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-input-search",
                    {
                      attrs: { placeholder: "点击选择部门", readOnly: "" },
                      on: { search: _vm.onSearch },
                      model: {
                        value: _vm.checkedDepartNameString,
                        callback: function ($$v) {
                          _vm.checkedDepartNameString = $$v
                        },
                        expression: "checkedDepartNameString",
                      },
                    },
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { slot: "enterButton", icon: "search" },
                          slot: "enterButton",
                        },
                        [_vm._v("选择")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.departDisabled,
                      expression: "!departDisabled",
                    },
                  ],
                  attrs: {
                    label: "租户分配",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        mode: "multiple",
                        placeholder: "请选择租户分配",
                        disabled: _vm.disableSubmit,
                      },
                      model: {
                        value: _vm.currentTenant,
                        callback: function ($$v) {
                          _vm.currentTenant = $$v
                        },
                        expression: "currentTenant",
                      },
                    },
                    _vm._l(_vm.tenantList, function (item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "身份",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-radio-group",
                    {
                      on: { change: _vm.identityChange },
                      model: {
                        value: _vm.identity,
                        callback: function ($$v) {
                          _vm.identity = $$v
                        },
                        expression: "identity",
                      },
                    },
                    [
                      _c("a-radio", { attrs: { value: "1" } }, [
                        _vm._v("普通用户"),
                      ]),
                      _c("a-radio", { attrs: { value: "2" } }, [
                        _vm._v("上级"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.departIdShow == true
                ? _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: "负责部门",
                        labelCol: _vm.labelCol,
                        wrapperCol: _vm.wrapperCol,
                      },
                    },
                    [
                      _c(
                        "a-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            mode: "multiple",
                            placeholder: "请选择负责部门",
                            optionFilterProp: "children",
                            getPopupContainer: (target) => target.parentNode,
                            dropdownStyle: {
                              maxHeight: "200px",
                              overflow: "auto",
                            },
                          },
                          model: {
                            value: _vm.departIds,
                            callback: function ($$v) {
                              _vm.departIds = $$v
                            },
                            expression: "departIds",
                          },
                        },
                        _vm._l(_vm.resultDepartOptions, function (item) {
                          return _c(
                            "a-select-option",
                            { key: item.key, attrs: { value: item.key } },
                            [_vm._v(_vm._s(item.title))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "头像",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("j-image-upload", {
                    staticClass: "avatar-uploader",
                    attrs: { text: "上传" },
                    model: {
                      value: _vm.fileList,
                      callback: function ($$v) {
                        _vm.fileList = $$v
                      },
                      expression: "fileList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "生日",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-date-picker", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: [
                          "birthday",
                          {
                            initialValue: !_vm.model.birthday
                              ? null
                              : _vm.moment(_vm.model.birthday, _vm.dateFormat),
                          },
                        ],
                        expression:
                          "['birthday', {initialValue:!model.birthday?null:moment(model.birthday,dateFormat)}]",
                      },
                    ],
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请选择生日",
                      getCalendarContainer: (node) => node.parentNode,
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "性别",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: ["sex", {}],
                          expression: "[ 'sex', {}]",
                        },
                      ],
                      attrs: {
                        placeholder: "请选择性别",
                        getPopupContainer: (target) => target.parentNode,
                      },
                    },
                    [
                      _c("a-select-option", { attrs: { value: 1 } }, [
                        _vm._v("男"),
                      ]),
                      _c("a-select-option", { attrs: { value: 2 } }, [
                        _vm._v("女"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "邮箱",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["email", _vm.validatorRules.email],
                        expression: "[ 'email', validatorRules.email]",
                      },
                    ],
                    attrs: { placeholder: "请输入邮箱" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "手机号码",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["phone", _vm.validatorRules.phone],
                        expression: "[ 'phone', validatorRules.phone]",
                      },
                    ],
                    attrs: {
                      placeholder: "请输入手机号码",
                      disabled: _vm.isDisabledAuth("user:form:phone"),
                    },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "座机",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("a-input", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["telephone", _vm.validatorRules.telephone],
                        expression: "[ 'telephone', validatorRules.telephone]",
                      },
                    ],
                    attrs: { placeholder: "请输入座机" },
                  }),
                ],
                1
              ),
              _c(
                "a-form-item",
                {
                  attrs: {
                    label: "工作流引擎",
                    labelCol: _vm.labelCol,
                    wrapperCol: _vm.wrapperCol,
                  },
                },
                [
                  _c("j-dict-select-tag", {
                    directives: [
                      {
                        name: "decorator",
                        rawName: "v-decorator",
                        value: ["activitiSync", {}],
                        expression: "['activitiSync', {}]",
                      },
                    ],
                    attrs: {
                      placeholder: "请选择是否同步工作流引擎",
                      type: "radio",
                      triggerChange: true,
                      dictCode: "activiti_sync",
                    },
                  }),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("depart-window", { ref: "departWindow", on: { ok: _vm.modalFormOk } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.disableSubmit,
              expression: "!disableSubmit",
            },
          ],
          staticClass: "drawer-bootom-button",
        },
        [
          _c(
            "a-popconfirm",
            {
              attrs: {
                title: "确定放弃编辑？",
                okText: "确定",
                cancelText: "取消",
              },
              on: { confirm: _vm.handleCancel },
            },
            [
              _c("a-button", { staticStyle: { "margin-right": ".8rem" } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
          _c(
            "a-button",
            {
              attrs: { type: "primary", loading: _vm.confirmLoading },
              on: { click: _vm.handleSubmit },
            },
            [_vm._v("提交")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }